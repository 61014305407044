import React from "react";
import moment from "moment";
import styled from "styled-components";
import { Auth } from "aws-amplify";
import axios from "axios";
import copy from "copy-to-clipboard";
import ReactGA from "react-ga4";
import Status from "../../../components/Status";
import CopyLink from "./CopyLink";
import ButtonUnderlineText from "../../../components/ButtonUnderlineText";
import { useGeneral } from "../../../context/GeneralContext";
import { useProperty } from "../../../context/PropertyContext";
import { propertyStatus, /*propertyType*/ } from "../../../static/Data";
// import { encodeURL } from "../../../utils/utils";
import { useState } from "react";
import DescriptionModal from "./DescriptionModal";
import removeUndefinedWord from "../../../helper/updateString";
import { types } from "../../../helper/properties";
import { useMutation } from "@tanstack/react-query";
import { patchProperty } from "../../../api/properties";
import FormModal from "../../../components/Modals/FormModal";

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 3px;
`;

const Description = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  max-height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TopLevelDetails = () => {
  const [descriptionStatus, setDescriptionStatus] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const [isVerified, setIsVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // const [isValid, setIsValid] = useState(true);
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const { setAlertModal } = useGeneral();
  const { property, setProperty } = useProperty();
  const [formData, setFormData] = useState({location: '', fullName: '', email: '', phoneNumber: ''})
  const [pType, setPType] = useState(property.classification);

  const reportShareLink = (category, label) => {
    ReactGA.event({
      action: "share_link",
      category: category,
      label: label,
    });
  };

  const shareProperty = () => {
    if (!property._id) return;
    Auth.currentAuthenticatedUser().then((userInfo) => {
      const customId = userInfo.attributes["custom:_id"];
      const link = `${process.env.REACT_APP_URL}/book/property/${property._id}/${customId}`;
      copy(link);
      reportShareLink("property/list", property.address);
      // }
      setAlertModal({
        show: true,
        title: "Copied",
        content: "Sharing the link enables lead booking for this Unit",
      });
    });
  };

  // const shareBuilding = () => {
  //   const shortStateUrl = encodeURL(property.state);
  //   const shortCityUrl = encodeURL(property.city);
  //   const shortAddressUrl = encodeURL(property.shortAddress);
  //   const link = `${process.env.REACT_APP_URL}/book/${shortStateUrl}/${shortCityUrl}/${shortAddressUrl}`;
  //   copy(link);
  //   setAlertModal({
  //     show: true,
  //     title: "Copied",
  //     content:
  //       "Share a link that allows booking appointments for all building vacancies",
  //   });
  //   reportShareLink(
  //     "property/list",
  //     `${shortAddressUrl}, ${shortCityUrl}, ${shortStateUrl}`
  //   );
  // };

  const changePropertyStatus = (selectedStatus) => {
    setProperty({ ...property, status: selectedStatus });

    Auth.currentSession().then((token) => {
      axios
        .put(
          `${process.env.REACT_APP_NODE_API_URL}/api/property/${property._id}`,
          { status: selectedStatus },
          {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setAlertModal({
              show: true,
              title: "Updated",
              content: "Property status updated",
            });
          } else {
            setAlertModal({
              show: true,
              title: "Error",
              content: "Error updating status",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setAlertModal({
            show: true,
            title: "Error",
            content: "Error updating status",
          });
        });
    });
  };

  const { mutateAsync: updateType, isPending: updatingType } = useMutation({
    mutationFn: (updatedFields) =>
      patchProperty(property._id, updatedFields),
  });

  const handleChangeType = async (e) => {
    const { value: newType } = e.target;
    const prevType = pType;
    setPType(newType);
    try {
      await updateType({classification: newType});
    } catch (error) {
      console.log("ERROR UPDATING TYPE: ", error);
      setPType(prevType);
    }
  };

  const handleFormModal = () =>{
    setShowModal(true);
  }

  const handleModalClose = () =>{
    setShowModal(false);
    setIsVerified(false);
    setIsSubmitted(false);
    setFormData({ fullName: '', email: '', phoneNumber: ''})
    setPhoneError('')
    setEmailError('')
  }

  return (
    <>
      <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-column align-items-start gap-1 align-self-stretch border-bottom">
          <div className="d-flex flex-column align-items-start gap-2 align-self-stretch">
            <div className="d-flex justify-content-between align-items-start align-self-stretch">
              <select
                className="form-select w-content text-capitalize"
                value={pType}
                disabled={updatingType}
                onChange={handleChangeType}
              >
                {types.map((type) => (
                  <option value={type} key={type}>{type}</option>
                ))}
              </select>
              <Status
                status={property.status}
                values={[]}
                onChange={(e) => changePropertyStatus(e[0].status)}
                placeholder={property.status}
                options={propertyStatus}
                size="sm"
                className="status-td"
              />
            </div>
            <div>
              <h3>
                {removeUndefinedWord(property.shortAddress)}
                {property.unit && `, Unit ${property.unit}`}
              </h3>
              <h3>
                {property.city && property.city}
                {property.state && `, ${property.state}`}
                {property.zipCode && `, ${property.zipCode}`}
              </h3>
            </div>
          </div>

          <div className="d-flex flex-wrap align-items-start gap-3 text-capitalize">
            <CopyLink onClick={handleFormModal} text="Share property" />
            
            {/* <CopyLink onClick={shareBuilding} text="Share building" /> */}
          </div>
        </div>

        <div className="d-flex flex-column align-items-start gap-2 ">
          <div className="d-flex flex-wrap align-items-start justify-content-between align-self-stretch border-bottom pb-3">
            <div className="d-flex flex-column gap-2">
              <span className="tags">Rent</span>
              <span className="body-xs-b">
                {property.price ? `$${property.price}` : "N/A"}
              </span>
            </div>
            <div className="d-flex flex-column gap-2">
              <span className="tags">Deposit</span>
              <span className="body-xs-b">
                ${property.deposit ? property.deposit : "N/A"}
              </span>
            </div>
            <div className="d-flex flex-column gap-2">
              <span className="tags">Beds</span>
              <span className="body-xs-b">
                {property.bedrooms ? `${property.bedrooms}BR` : "N/A"}
              </span>
            </div>
            <div className="d-flex flex-column gap-2">
              <span className="tags">Baths</span>
              <span className="body-xs-b">
                {property.bathrooms ? `${property.bathrooms}BA` : "N/A"}
              </span>
            </div>
            <div className="d-flex flex-column gap-2">
              <span className="tags">SQFT</span>
              <span className="body-xs-b">
                {property.sqft ? `${property.sqft}` : "N/A"}
              </span>
            </div>
            <div className="d-flex flex-column gap-2">
              <span className="tags">Available</span>
              <span className="body-xs-b">
                {property.dateAvailableTs
                  ? moment(property.dateAvailableTs).format("MM/DD/YY")
                  : "-"}
              </span>
            </div>
          </div>

          <DescriptionContainer>
            {property.propertyDescription &&
            property.propertyDescription.length > 0 ? (
              <Description className="body-xs">
                {property.propertyDescription}
              </Description>
            ) : null}

            {property.propertyDescription &&
            property.propertyDescription.length > 0 ? (
              <div className="d-flex align-items-start">
                <ButtonUnderlineText
                  variantType="primary"
                  onClick={() => setDescriptionStatus(true)}
                >
                  See full description
                </ButtonUnderlineText>
              </div>
            ) : null}
          </DescriptionContainer>
        </div>
      </div>
      <DescriptionModal
        show={descriptionStatus}
        description={property.propertyDescription}
        onHide={() => setDescriptionStatus(false)}
      />
      <FormModal 
        show={showModal} 
        onHide={()=> handleModalClose()} 
        isVerified={isVerified} 
        isSubmitted={isSubmitted} 
        setIsVerified={setIsVerified} 
        setIsSubmitted={setIsSubmitted}
        formData={formData}
        setFormData={setFormData}
        emailError={emailError}
        setEmailError={setEmailError}
        phoneError={phoneError}
        setPhoneError={setPhoneError}
        />
    </>
  );
};

export default TopLevelDetails;

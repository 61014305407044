import './Properties.scss';

import React, { useEffect, useState } from 'react';

import {
  options,
  propertyStatus,
  propertyFilterStatus,
  propertyType,
  propertyListMenuItems,
} from '../../static/Data';
import StyledSelect from '../../components/Status';
import StyledFilterDropdown from '../../components/Select';
import Button from '../../components/Button';
import { PlusCircle, MoreVertical } from 'react-feather';
import CustomDropdown from '../../components/Dropdown';
import TabletTable from './components/Tablet/Tablet-Table';
import MobileTable from './components/Mobile/Mobile-Table';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import { propertyBookingLink } from '../../utils/Properties';
import {
  Button as BootstrapButton,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Sidebar from '../../components/SideBar';
import PagePagination from '../../components/Pagination';
import { IoMdArrowUp } from 'react-icons/io';
import { IoMdArrowDown } from 'react-icons/io';

//------------alplify imports----------------//
import { Auth } from 'aws-amplify';
import { usePubSub } from '../../context/PubSubContext';
import { useGeneral } from '../../context/GeneralContext';
import propertyDummyImage from '../../assests/images/6-thumb.png';
import getImageUrl from '../../helper/checkImageFormat';
import removeUndefinedWord from '../../helper/updateString';
import { useProperty } from '../../context/PropertyContext';
import ButtonDropdown from '../../components/Buttons/ButtonDropdown';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import { Center, Loader } from '@mantine/core';

const PropertiesList = () => {
  const navigate = useNavigate();
  const { setAlertModal, timezone } = useGeneral();
  const [activeFilterType, setActiveFilterType] = useState('All');
  //Filter status
  const [filterStatus, setFilterStatus] = useState('active');
  const [activeFilterStatus, setActiveFilterStatus] = useState('Active');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loading, setLoader] = useState(false);
  const [searchParam, setSearchParam] = useState('');

  const [list, setList] = useState([]);
  const [originalList, setOriginalList] = useState([]);

  const [key, setkey] = useState(0);
  const imageUrl = process.env.REACT_APP_IMAGE_PATH;
  const [totalPages, setTotalPages] = useState(null);
  const [recordPerPage, setRecordPerPage] = useState(null);
  const [totalRecords, setTotalRecords] = useState(null);
  const [actualPage, setActualPage] = useState(1);
  const [term, setTerm] = useState('');
  const { subscribeToPubSub } = usePubSub();
  const [address, setAddress] = useState([]);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const { leadsCount } = useProperty();
  const [showImportModal, setShowImportModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState([]);

  const params = useParams();
  const location = useLocation();
  const isEditPage = location.pathname.includes('edit');

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSelectProperty = (propertyId) => {
    setSelectedProperties((prevSelected) =>
      prevSelected.includes(propertyId)
        ? prevSelected.filter((id) => id !== propertyId)
        : [...prevSelected, propertyId]
    );
  };

  const isPropertySelected = (propertyId) =>
    selectedProperties.includes(propertyId);

  useEffect(() => {
    getPropertyList();
    if (!window.location.href.includes('localhost')) {
      ReactGA.initialize(`${process.env.REACT_APP_GA}`);
    }
  }, [filterStatus, term, sort, order, actualPage]);

  const getPropertyList = () => {
    if (!sort || !order) {
      return;
    }
    setLoader(false);

    let url = process.env.REACT_APP_NODE_API_URL;

    if (term) {
      url = url + `/api/property?term=${term}`;
    } else {
      url = url + '/api/property';
    }
    if (actualPage > 1) {
      if (url.includes('?term')) {
        url = url + `&page=${actualPage}`;
      } else {
        url = url + `?page=${actualPage}`;
      }
    }

    if (filterStatus) {
      if (url.includes('?term') || url.includes('?page')) {
        url = url + `&status=${filterStatus}`;
      } else {
        url = url + `?status=${filterStatus}`;
      }
    }

    url += `&sort=${sort}&order=${order}`;

    Auth.currentSession()
      .then((auth) => {
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${auth.getIdToken().getJwtToken()}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setLoader(true);
              const transformedProperties = response.data.properties.map(
                (property) => {
                  const { shortAddress, unit, city, state, zipCode } = property;

                  let firstString = '';

                  if (shortAddress && shortAddress.includes(',')) {
                    const addressArray = shortAddress.split(',');

                    firstString = addressArray[0].trim();
                  } else if (shortAddress) {
                    firstString = shortAddress.trim();
                  }

                  const firstLine = `${firstString} ${
                    unit ? `, Unit ${unit}` : ''
                  }`;

                  const addressParts = [city, state, zipCode].filter(
                    (value) => value
                  );
                  const secondLine = addressParts.join(', ');

                  return {
                    firstLine: firstLine,
                    secondLine: secondLine,
                  };
                }
              );

              //updating the address of the property to an object of firstLine and secondLine
              response.data.properties.map((property, index) => {
                property.address = transformedProperties[index];
                return property;
              });
              setList(response.data.properties);
              setOriginalList(response.data.properties);

              setAddress(transformedProperties);
              const deviceIds = response.data.properties
                .map((property) => {
                  if (property.kit) {
                    return property.kit.device_id;
                  } else {
                    return null;
                  }
                })
                .filter(
                  (deviceId) => deviceId !== undefined && deviceId !== null
                )
                .map((deviceId) => subscribeToPubSub(deviceId));

              if (response.data.totalPages || response.data.totalPages === 0) {
                setTotalPages(response.data.totalPages);
              }
              if (response.data.element_per_page) {
                setRecordPerPage(response.data.element_per_page);
              }
              if (response.data.totalResults) {
                setTotalRecords(response.data.totalResults);
              }
            } else {
              setAlertModal({
                show: true,
                title: 'Error',
                content: 'Error getting properties',
              });
            }
          })
          .catch((error) => {
            console.log(error);
            setAlertModal({
              show: true,
              title: 'Error',
              content: 'Error getting properties',
            });
          });
      })
      .catch((error) => {
        console.log(error);
        setAlertModal({
          show: true,
          title: 'Error',
          content: 'Error getting properties',
        });
      });
  };

  const handleDeleteProperty = (id) => {
    Auth.currentSession().then((token) => {
      axios
        .delete(`${process.env.REACT_APP_NODE_API_URL}/api/property/${id}`, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setAlertModal({
              show: true,
              title: 'Deleted',
              content: 'Property deleted successfully',
            });
            getPropertyList();
          } else {
            setAlertModal({
              show: true,
              title: 'Error',
              content: 'Error deleting property',
            });
          }
        })
        .catch((error) => {
          setAlertModal({
            show: true,
            title: 'Error',
            content: 'Error deleting property',
          });
        });
    });
  };

  const getKey = async (e, id) => {
    setkey(id);
  };
  const handleChangeStatus = async (e, id) => {
    const selectedStatus = e[0].status;
    const updatedList = list.map((property) => {
      if (property._id === id) {
        return { ...property, status: selectedStatus };
      }
      return property;
    });
    setList(updatedList);
    try {
      var url = process.env.REACT_APP_NODE_API_URL + '/api/property/' + key;
      const token = await Auth.currentSession();

      const response = await axios.put(
        url,
        { status: selectedStatus },
        {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        }
      );
      if (response.status === 200) {
        setAlertModal({
          show: true,
          title: 'Updated',
          content: 'Property status updated',
        });
      } else {
        setAlertModal({
          show: true,
          title: 'Error',
          content: 'Error changing property status',
        });
      }
    } catch (error) {
      console.log(error);
      if (error.statusCode === 401) {
        setAlertModal({
          show: true,
          title: 'Error',
          content: 'Error changing property status',
        });
      } else {
        setAlertModal({
          show: true,
          title: 'Error',
          content: 'Error changing property status',
        });
      }
    }
  };

  const handleFilterType = (e) => {
    if (e[0].label === 'All') {
      setList(originalList);
      setActiveFilterType('All');
    } else {
      const filteredData = originalList.filter(
        (data) => data.classification === e[0].type
      );
      setList(filteredData);
      setActiveFilterType(e[0].label);
    }
  };

  const handleFilterStatus = (e) => {
    if (e[0].label === 'All') {
      setFilterStatus('null');
      setActiveFilterStatus('All');
      setActiveFilterType('All');
    } else if (e[0].label === 'Active') {
      setFilterStatus('active');
      setActiveFilterStatus('Active');
      setActiveFilterType('All');
    } else if (e[0].label === 'Inactive') {
      setFilterStatus('inactive');
      setActiveFilterStatus('Inactive');
      setActiveFilterType('All');
    } else if (e[0].label === 'Lease agreement sent') {
      setFilterStatus('lease agreement sent');
      setActiveFilterStatus('Lease agreement sent');
      setActiveFilterType('All');
    } else if (e[0].label === 'Application received') {
      setFilterStatus('application received');
      setActiveFilterStatus('Application received');
      setActiveFilterType('All');
    } else if (e[0].label === 'Rented') {
      setFilterStatus('rented');
      setActiveFilterStatus('Rented');
      setActiveFilterType('All');
    } else if (e[0].label === 'Sold') {
      setFilterStatus('sold');
      setActiveFilterStatus('Sold');
      setActiveFilterType('All');
    }
  };

  const handleorder = (e) => {
    if (e[0].label === 'Price (Low to High)') {
      // let orderdata = [...list]
      // orderdata.sort((a, b) => a.price - b.price)
      // setList(orderdata)
      setSort('price');
      setOrder('asc');
    } else if (e[0].label === 'Price (High to Low)') {
      // let orderdata = [...list]
      // orderdata.sort((a, b) => b.price - a.price)
      // setList(orderdata)
      setSort('price');
      setOrder('desc');
    }
    // } else if (e[0].label === "Available (desc)") {
    //   // let orderdata = [...list]
    //   //   orderdata.sort((a, b) => a.price - b.price)
    //   // setList(orderdata)
    //   setSort("active");
    //   setOrder("desc");
    // } else if (e[0].label === "Available (asc)") {
    //   // let orderdata = [...list]
    //   //   orderdata.sort((a, b) => b.price - a.price)
    //   // setList(orderdata)
    //   setSort("active");
    //   setOrder("asc");
    // } else if (e[0].label === "Rented (desc)") {
    //   // let orderdata = [...list]
    //   // orderdata.sort((a, b) => b.category - a.category)
    //   // setList(orderdata)
    //   setSort("rented");
    //   setOrder("desc");
    // } else if (e[0].label === "Rented (asc)") {
    //   // let orderdata = [...list]
    //   // orderdata.sort((a, b) => b.category - a.category)
    //   // setList(orderdata)
    //   setSort("rented");
    //   setOrder("asc");
    // }
    // else if (e[0].label === "Listed (desc)") {
    //   // let orderdata = [...list]
    //   // orderdata.sort((a, b) => b.listedTs - a.listedTs)
    //   // setList(orderdata)
    //   setSort("listed");
    //   setOrder("desc");
    // } else if (e[0].label === "Listed (asc)") {
    //   // let orderdata = [...list]
    //   // orderdata.sort((a, b) => a.listedTs - b.listedTs)
    //   // setList(orderdata)
    //   setSort("listed");
    //   setOrder("asc");
    // }
    else if (e[0].label === 'Most Recent') {
      // let orderdata = [...list]
      // orderdata.sort((a, b) => b.listedTs - a.listedTs)
      // setList(orderdata)
      setSort('date');
      setOrder('desc');
    } else if (e[0].label === 'Oldest') {
      // let orderdata = [...list]
      // orderdata.sort((a, b) => b.listedTs - a.listedTs)
      // setList(orderdata)
      setSort('date');
      setOrder('asc');
    }
  };

  const reportShareLink = (category, label) => {
    ReactGA.event({
      action: 'share_link',
      category: category,
      label: label,
    });
  };

  const copyToClipboard = () => {
    var userId = list ? list[0].user._id : '';
    var link = process.env.REACT_APP_URL + '/book/list/' + userId;
    copy(link);
    setAlertModal({
      show: true,
      title: 'Copied',
      content:
        'Calendar link copied. You can send this link to leads, so that they can create appointments.',
    });
    reportShareLink(
      'agent',
      `${list[0].user.firstName} ${list[0].user.lastName}`
    );
  };

  function minutesAgo(lastPing) {
    let mins = Math.round((new Date().getTime() - lastPing) / (1000 * 60));
    if (mins > 5 || isNaN(mins)) {
      return 'offline';
    }
    return 'online';
  }

  const handleReset = () => {
    setTerm(null);
    setSearchParam('');
  };
  const handleSearch = () => {
    setTerm(searchParam);
  };

  const handleNextPage = (nextPage) => {
    if (totalPages >= nextPage && nextPage > 0) {
      setActualPage(nextPage);
    }
  };
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const handleMenuItemClick = async (action, id) => {
    if (action === "Edit property details") {
      navigate(`/property/edit/${id}`)
    } else if (action === "Open prospect booking page") {
      const property = list.find((property) => property._id === id);
      const link = await propertyBookingLink(property);
      window.open(link, '_blank');
    } else if (action === 'Copy prospect booking link') {
      const property = list.find((property) => property._id === id);
      const link = await propertyBookingLink(property);
      copy(link);
      setAlertModal({
        show: true,
        title: 'Copied',
        content: 'Sharing the link enables lead booking for this Unit',
      });
    } else if (action === "View all property leads") {
      navigate(`/property/${id}?activeTab=leads`)
    } else if (action === "Delete property") {
      handleDeleteProperty(id);
    }
  };

  const sortColumn = (e) => {
    setSort(e);
    setOrder(order === 'asc' ? 'desc' : 'asc');
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setError(false);
  };

  const handleClose = () => {
    setShowImportModal(false);
    setError(false);
    setValidationError(false);
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      setError(true);
      return;
    }

    try {
      const parsedData = await new Promise((resolve) => {
        Papa.parse(selectedFile, {
          complete: (result) => {
            resolve(result.data);
          },
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        });
      });

      if (
        parsedData.some(
          (item) =>
            !item.address ||
            !item.aptOrSuite ||
            !item.city ||
            !item.state ||
            !item.zipCode
        )
      ) {
        setValidationError(true);
        return;
      }

      setShowImportModal(false);
      setValidationError(false);
      await submitData(parsedData);
    } catch (error) {
      console.error('Error parsing CSV:', error);
      setError(true);
    }
  };

  const mapUtilityValue = (value) =>
    value === 'Included In Rent' ? 'included' : 'tenant';
  const convertToSecond = (ele) => Number(ele?.split(' ')[0]) * 60 * 1000;
  const mapParkingType = (type) => {
    switch (type) {
      case 'Street Parking (Standard)':
        return 'streetStandard';
      case 'Street Parking (Residential Permit Enforced)':
        return 'streetResidential';
      case 'Uncovered Parking':
        return 'uncovered';
      case 'Covered Parking':
        return 'covered';
      case 'Secured Access Garage':
        return 'secured';
      default:
        return '';
    }
  };

  const submitData = async (data) => {
    if (!data.length) return;
    for (const jsonData of data) {
      let payload = {
        amenitiesAndFeatures: {
          floorPlanHighlights: [],
          kitchenFeatures: [],
          buildingFeatures: [],
        },
        unit: jsonData.aptOrSuite ? jsonData.aptOrSuite?.toString() : '',
        propertyType: jsonData.propertyType || 'apartment',
        category: jsonData.rentOrSale || 'rent',
        sqft: jsonData.sqft ? jsonData.sqft?.toString() : '',
        bedrooms: jsonData.numberOfBedrooms
          ? jsonData.numberOfBedrooms?.toString()
          : '',
        bathrooms: jsonData.numberOfBathrooms
          ? jsonData.numberOfBathrooms?.toString()
          : '',
        propertyDescription: jsonData.propertyDescription || '',
        utilities: {
          water: mapUtilityValue(jsonData.water)
            ? mapUtilityValue(jsonData.water)
            : 'tenant',
          electricity: mapUtilityValue(jsonData.electricity)
            ? mapUtilityValue(jsonData.electricity)
            : 'tenant',
          gas: mapUtilityValue(jsonData.gas)
            ? mapUtilityValue(jsonData.gas)
            : 'tenant',
          trash: mapUtilityValue(jsonData.trash)
            ? mapUtilityValue(jsonData.trash)
            : 'tenant',
          sewage: mapUtilityValue(jsonData.sewage)
            ? mapUtilityValue(jsonData.sewage)
            : 'tenant',
          notes: jsonData.utilitiesNotes || '',
        },
        parking: {
          type: mapParkingType(jsonData.parkingType)
            ? mapParkingType(jsonData.parkingType)
            : 'streetResidential',
          spacesNumber: jsonData.numberOfParkingSpaces
            ? jsonData.numberOfParkingSpaces?.toString()
            : '',
          monthlyCostPerSpace: jsonData.monthlyCostPerSpace
            ? jsonData.monthlyCostPerSpace?.toString()
            : '',
          included: jsonData.parkingIncludedInRent,
          notes: jsonData.parkingNotes || '',
        },
        pets: {
          allowed:
            jsonData.petsAllowed === 'No pets allowed' ? true : false || true,
          dogs: {
            allowed:
              jsonData.petsAllowed === 'Yes, dogs allowed' ? true : false,
            weight: jsonData.dogsWeightLimit
              ? jsonData.dogsWeightLimit?.toString()
              : '',
            maxAllowed: jsonData.dogsMaxNumberOfDogsAllowed
              ? jsonData.dogsMaxNumberOfDogsAllowed?.toString()
              : '',
          },
          cats: {
            allowed:
              jsonData.petsAllowed === 'Yes, cats allowed' ? true : false,
            weight: jsonData.catsWeightLimit
              ? jsonData.catsWeightLimit?.toString()
              : '',
            maxAllowed: jsonData.catsMaxNumberOfCatsAllowed
              ? jsonData.catsMaxNumberOfCatsAllowed?.toString()
              : '',
          },
          deposit: jsonData.petDeposit ? jsonData.petDeposit?.toString() : '',
          monthlyPetRent: jsonData.monthlyPetRent
            ? jsonData.monthlyPetRent?.toString()
            : '',
          notes: jsonData.petPolicyNotes?.toString() || '',
        },
        rentalApplicationForm: {
          url: jsonData.applicationURL
            ? jsonData.applicationURL?.toString()
            : '',
          fee: jsonData.applicationFee
            ? jsonData.applicationFee?.toString()
            : '',
          instructions: jsonData.applicationInstructions?.toString() || '',
          enable: jsonData.rentalApplicationFormAndInstructions,
        },
        timeInstructions: convertToSecond(jsonData.advanceProspectsTime),
        company: {
          name: jsonData.propertyManagementGroup || '',
          website: jsonData.companyWebsite || '',
          phone: jsonData.companyPhoneNumber
            ? jsonData.companyPhoneNumber?.toString()
            : '',
          contactInformation:
            jsonData.includeCompanyContactInformationInProspectCommunication,
        },
        leasingAgent: {
          firstName: jsonData.agentFirstName || '',
          lastName: jsonData.agentLastName || '',
          phone: jsonData.agentPhoneNumber
            ? jsonData.agentPhoneNumber?.toString()
            : '',
          email: jsonData.agentEmail || '',
          contactInformation:
            jsonData.includeAgentContactInformationInProspectCommunication,
        },
        propertyManager: {
          onSite:
            jsonData.propertyOrBuildingManagerOnSite === 'Yes' ? true : false,
          firstName: jsonData.managerFirstName || '',
          lastName: jsonData.managerLastName || '',
          phone: jsonData.managerPhoneNumber
            ? jsonData.managerPhoneNumber?.toString()
            : '',
          email: jsonData.managerEmail || '',
          contactInformation:
            jsonData.includeManagerContactInformationInProspectCommunication,
        },
        maintenanceManager: {
          onSite: jsonData.maintenanceManagerOnSite === 'Yes' ? true : false,
          firstName: jsonData.maintenanceManagerFirstName || '',
          lastName: jsonData.maintenanceManagerLastName || '',
          phone: jsonData.maintenanceManagerPhoneNumber
            ? jsonData.maintenanceManagerPhoneNumber?.toString()
            : '',
          email: jsonData.maintenanceManagerEmail || '',
          contactInformation:
            jsonData.includeMaintenanceManagerContactInformationInProspectCommunication,
        },
        kit: null,
        price: jsonData.monthlyRent ? jsonData.monthlyRent?.toString() : '',
        deposit: jsonData.securityDeposit
          ? jsonData.securityDeposit?.toString()
          : '',
        shortAddress: jsonData.address || '',
        city: jsonData.city || '',
        zipCode: jsonData.zipCode || '',
        state: jsonData.state || '',
        address: jsonData.address || '',
        dateAvailableTs: jsonData.availableMoveInDate
          ? new Date(
              jsonData.availableMoveInDate.replace(
                /(\d{2})-(\d{2})-(\d{4})/,
                '$3-$1-$2'
              )
            ).getTime()
          : new Date().getTime(),
        primaryImage: '',
        prospectIdentityVerification: jsonData.idOrDriversLicenseVerification,
        doorUnlockLink: jsonData.doorUnlockLink,
        leaseTermOptions: [
          jsonData.leaseTermOptions ? jsonData.leaseTermOptions : '',
        ],
        propertyAccessCodes: {
          buildingCode: jsonData.buildingAccessCode || '',
          elevatorCode: jsonData.elevatorCode || '',
          instructions: jsonData.additionalInstructions || '',
          enable: jsonData.propertyAccessCodesAndInstructions,
        },
      };

      let methodForm = '';
      let url = '';
      if (isEditPage) {
        methodForm = `put`;
        url = `${process.env.REACT_APP_NODE_API_URL}/api/property/${params.id}`;
      } else {
        methodForm = `post`;
        url = `${process.env.REACT_APP_NODE_API_URL}/api/property`;
      }
      Auth.currentSession().then((token) => {
        axios({
          method: methodForm,
          url: url,
          data: payload,
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
          .then((response) => {
            if (isEditPage) {
              setAlertModal({
                show: true,
                title: 'Success',
                content: 'Property Edited Successfully',
              });
              navigate(`/property/${params.id}`);
            } else {
              setAlertModal({
                show: true,
                title: 'Success',
                content: 'Property Created Successfully',
              });
              getPropertyList();
            }
          })
          .catch((error) => {
            console.log(error);
            if (isEditPage) {
              setAlertModal({
                show: true,
                title: 'Error',
                content: 'Error Editing Property',
              });
            } else {
              setAlertModal({
                show: true,
                title: 'Error',
                content: 'Error Creating Property',
              });
            }
          });
      });
    }
  };

  const [showCopyToClipboard, setShowCopyToClipboard] = useState(false);

  const handleSelectedPropertiesShare = async () => {
    try {
      const authData = await Auth.currentSession();
      const userId = authData.getIdToken().payload['custom:_id'];
      const propertyIds = selectedProperties.map((id) => id).join(',');
      const link = `${process.env.REACT_APP_URL}/book/list/${userId}?properties=${propertyIds}`;
      copy(link);
      setAlertModal({
        show: true,
        title: 'Copied',
        content:
          'Calendar link copied. You can send this link to leads, so that they can create appointments.',
      });
      reportShareLink(
        'agent',
        `${list[0].user.firstName} ${list[0].user.lastName}`
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Sidebar>
      <div className='page-main-head align-items-center'>
        <Row className=''>
          <Col
            xxl={8}
            xl={6}
            lg={6}
          >
            <div className='d-flex align-items-center flex-wrap flex-md-nowrap justify-content-center justify-content-md-start'>
              <h2 className='color-black'>Properties</h2>
              <Form>
                <Form.Group
                  className='search-control-group d-flex '
                  controlId='search-control'
                >
                  <Form.Control
                    type='text'
                    placeholder='Search properties'
                    value={searchParam}
                    onChange={(e) => setSearchParam(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleSearch}
                  />

                  {term && (
                    <BootstrapButton
                      onClick={handleReset}
                      className='cancel-filter-button'
                      variant='link'
                    >
                      X
                    </BootstrapButton>
                  )}
                  <BootstrapButton
                    className={
                      searchParam !== '' ? 'search-btn active' : 'search-btn'
                    }
                    onClick={handleSearch}
                  ></BootstrapButton>
                </Form.Group>
              </Form>
            </div>
          </Col>
          <Col
            xxl={4}
            xl={6}
            lg={6}
          >
            <div className='d-flex align-items-center justify-content-center justify-content-md-end mobile-wrap'>
              <ButtonDropdown
                title={'Add New Listing'}
                icon={
                  <PlusCircle
                    size='20px'
                    className='mr-10'
                  />
                }
                items={[
                  {
                    name: 'Add Manually',
                    handleClick: () => navigate('/property/add'),
                  },
                  {
                    name: 'Import',
                    handleClick: () => setShowImportModal(true),
                  },
                ]}
              />
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        centered
        show={showImportModal}
        size='lg'
        dialogClassName='modal-dialog'
      >
        <Modal.Header>
          <Modal.Title>Import CSV File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group
            controlId='formFile'
            className='mt-2'
          >
            <Form.Control
              type='file'
              onChange={handleFileChange}
            />
          </Form.Group>
          {error && (
            <label style={{ color: 'red' }}>Import your CSV file</label>
          )}
          {validationError && (
            <label style={{ color: 'red' }}>
              Fields address, aptOrSuite, city, state, zipCode are empty.
            </label>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <>
        <div className='px-5'>
          <div className='filters align-items-center'>
            <Row className='align-items-center'>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={8}
                sm={12}
                className='d-flex flex-wrap gap-2 justify-content-between justify-content-sm-start'
              >
                <StyledFilterDropdown
                  modalWidth={'100px'}
                  values={[]}
                  onChange={(e) => handleFilterType(e)}
                  placeholder={`Type : ${activeFilterType}`}
                  options={propertyType}
                />
                <StyledFilterDropdown 
                  modalWidth={'180px'}
                  values={[]}
                  onChange={(e) => handleFilterStatus(e)}
                  placeholder={`Status: ${activeFilterStatus}`}
                  options={propertyFilterStatus}
                />
                {selectedProperties.length > 0 && (
                  <Button
                    className='ml-2'
                    onClick={handleSelectedPropertiesShare}
                    style={{
                      backgroundColor: 'black',
                      color: 'white',
                      minWidth: '80px',
                      height: '37px',
                      border: 'none',
                    }}
                  >
                    Share Calendar
                  </Button>
                )}
                {showCopyToClipboard && <div>Link copied to clipboard!</div>}
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={4}
                sm={12}
                className='d-flex justify-content-md-end justify-content-sm-start pt-md-0 pt-2 d-xs-block d-md-none'
              >
                <StyledFilterDropdown
                  className=''
                  values={[]}
                  onChange={(e) => handleorder(e)}
                  placeholder={options[0].label}
                  options={options}
                />
              </Col>
            </Row>
          </div>
          {loading === false ? (
            <Center h={100}>
              <Loader />
            </Center>
          ) : (
            <>
              {screenWidth > 1200 ? (
                <Table className='property-table'>
                  <thead>
                    <tr>
                      <th></th>
                      <th className='text-start'>Property details</th>
                      <th
                        className='text-start'
                        onClick={() => sortColumn('price')}
                      >
                        Price
                        <span>
                          {order === 'desc' && sort === 'price' ? (
                            <IoMdArrowUp style={{ color: '#5d5fef' }} />
                          ) : order === 'asc' && sort === 'price' ? (
                            <IoMdArrowDown style={{ color: '#5d5fef' }} />
                          ) : (
                            <IoMdArrowDown />
                          )}
                        </span>
                      </th>
                      <th className='text-center'>Leads</th>
                      <th
                        className='text-center'
                        onClick={() => sortColumn('date')}
                      >
                        Listed date
                        <span>
                          {order === 'desc' && sort === 'date' ? (
                            <IoMdArrowUp style={{ color: '#5d5fef' }} />
                          ) : order === 'asc' && sort === 'date' ? (
                            <IoMdArrowDown style={{ color: '#5d5fef' }} />
                          ) : (
                            <IoMdArrowDown />
                          )}
                        </span>
                      </th>
                      <th>Property status</th>
                      <th className='text-end'>More actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((element, index) => (
                      <tr
                        key={element._id}
                        className='clickable-row'
                      >
                        <td style={{ width: '30px' }}>
                          <input
                            type='checkbox'
                            checked={isPropertySelected(element._id)}
                            onChange={() => handleSelectProperty(element._id)}
                          />
                        </td>
                        <Link
                          to={`/property/${element._id}`}
                          className='row-link'
                          style={{ display: 'contents' }}
                        >
                          <td className='image-td'>
                            <div className=''>
                              <Link
                                to={`/property/${element._id}`}
                                className='properties-details-linkImage'
                              >
                                <span className='position-relative d-inline-block'>
                                  <img
                                    style={{ borderRadius: '4px' }}
                                    width={224}
                                    height={126}
                                    src={
                                      element.images?.[0] ??
                                      getImageUrl(element.primaryImage) ??
                                      propertyDummyImage
                                    }
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = propertyDummyImage;
                                    }}
                                    alt='properties'
                                  />

                                  {/* <span
                                  // className={`text-uppercase properties-kit-status properties-online`}
                                  className={`text-uppercase properties-kit-status properties-${minutesAgo(
                                    element.lastPing)}`}
                                >
                                  {minutesAgo(element.lastPing)}

                                </span> */}
                                </span>
                              </Link>
                            </div>
                            <div className='d-flex flex-column image-content'>
                              <span className='type'>
                                {element.classification}
                              </span>
                              <span className='property-address'>
                                {removeUndefinedWord(element.address.firstLine)}
                              </span>
                              <span className='property-address'>
                                {removeUndefinedWord(
                                  element.address.secondLine
                                )}
                              </span>
                              <div className='d-flex flex-row gap-2'>
                                <span className='property-tags'>
                                  <span className='body-xs-b'>
                                    {element.bedrooms &&
                                      `${element.bedrooms}BR`}
                                  </span>
                                </span>
                                <span className='property-tags'>
                                  <span className='body-xs-b'>
                                    {element.bathrooms &&
                                      `${element.bathrooms}BA`}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className='price'>${element.price}</span>
                          </td>
                          <td>
                            <span className='leads'>
                              {/* {leadsCount.all ? leadsCount.all : 0} */}
                            </span>
                          </td>
                          <td>
                            <span className='date'>
                              {element.listedTs
                                ? moment(element.listedTs).format('MM/DD/YYYY')
                                : 'No Listed Date'}
                            </span>
                          </td>
                        </Link>
                        <td onMouseOver={(e) => getKey(e, element._id)}>
                          <StyledSelect
                            status={element.status}
                            values={[]}
                            onChange={(e) => handleChangeStatus(e, element._id)}
                            placeholder={element.status}
                            options={propertyStatus}
                            size='sm'
                            className='status-td'
                            style={{ pointerEvents: 'auto' }}
                          />
                        </td>
                        <td>
                          <div className='more-actions'>
                            <CustomDropdown
                              icon={<MoreVertical />}
                              menuItems={propertyListMenuItems}
                              onMenuItemClick={(action) =>
                                handleMenuItemClick(action, element._id)
                              }
                              style={{ pointerEvents: 'auto' }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : screenWidth > 1200 ? (
                <TabletTable
                  list={list}
                  imageUrl={imageUrl}
                  // minutesAgo={minutesAgo}
                  timezone={timezone}
                  getKey={getKey}
                  handleChangeStatus={handleChangeStatus}
                  propertyStatus={propertyStatus}
                  address={address}
                />
              ) : (
                <MobileTable
                  list={list}
                  imageUrl={imageUrl}
                  // minutesAgo={minutesAgo}
                  timezone={timezone}
                  getKey={getKey}
                  handleChangeStatus={handleChangeStatus}
                  propertyStatus={propertyStatus}
                  address={address}
                />
              )}
            </>
          )}
          {totalPages > 1 && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <PagePagination
                totalPages={totalPages}
                actualPage={actualPage}
                handlePageChange={handleNextPage}
              />
            </div>
          )}
        </div>
      </>
    </Sidebar>
  );
};

export default PropertiesList;
